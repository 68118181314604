<template>
    <div class=container>
        <ul v-if="clb" class=sidebar>
            <b>Research Areas</b>
            <li v-for="item in catelogs" :key="item.id"> <router-link class=sidetext
                    :to="{ path: '/projects/categories', query: { cid: item.title } }">{{ item.title }}</router-link>
            </li>
        </ul>
        <ul class=projectList v-if="pjlb && pjsb">
            <li class=projectElement v-for="project in pjl[category]" :key=project.id>
                <div v-if="pjs[project].type == 0">
                    <router-link
                        :to="{ path: '/projects/categories/project', query: { cid: this.$route.query.cid, pid: pjs[project].filename } }"><img
                            class=PLEIcon :src=pjs[project].url /> </router-link>
                    <router-link class=PLEText
                        :to="{ path: '/projects/categories/project', query: { cid: this.$route.query.cid, pid: pjs[project].filename } }">
                        <p class=title>{{ pjs[project].name }}</p>
                        <p class=date>{{ pjs[project].year }}</p>
                    </router-link>
                </div>
                <div v-else-if="pjs[project].type == 1">
                    <a :href="pjs[project].link"> <img class=PLEIcon :src=pjs[project].url /> </a>
                    <a class=PLEText :href="pjs[project].link">
                        <p class=title>{{ pjs[project].name }}</p>
                        <p class=date>{{ pjs[project].year }}</p>
                    </a>
                </div>
                <div v-else>
                    <router-link
                        :to="{ path: pjs[project].link, query: { cid: this.$route.query.cid, pid: pjs[project].filename } }"><img
                            class=PLEIcon :src=pjs[project].url /> </router-link>
                    <router-link class=PLEText
                        :to="{ path: pjs[project].link, query: { cid: this.$route.query.cid, pid: pjs[project].name } }">
                        <p class=title>{{ pjs[project].name }}</p>
                        <p class=date>{{ pjs[project].year }}</p>
                    </router-link>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import catelogs from "@/assets/categories/catelogs.json"
import pjl from "@/assets/categories/projectlist.json"
import pjs from "@/assets/categories/projects.json"

const clb = catelogs.length !== 0;
const pjlb = pjl.length !== 0;
const pjsb = pjs.length !== 0;

export default {
    name: 'ProjectCategory',
    data() {
        return {
            catelogs: catelogs,
            pjs: pjs,
            pjl: pjl,
            clb: clb,
            pjsb: pjsb,
            pjlb: pjlb
        }
    },
    computed: {
        category: function () {
            return this.$route.query.cid
        }
    }
}
</script>

<style scoped>
.container {
    margin-left: auto;
    margin-right: auto;
    width: 1000px;
}

/* sidebar */
.sidebar {
    font-size: 18px;
    float: right;
    top: 20px;
    margin-top: -5px;
    margin-right: -230px;
    text-align: left;
    position: sticky;
    list-style: none;
    line-height: 1.6em;
}

.sidetext {
    text-decoration: none;
    color: black;
    line-height: 1.8em;
}

.sidetext:hover {
    color: rgba(103, 103, 104, 0.842)
}

/* ul */
.projectList {
    width: 1000px;
    margin-bottom: 10vh;
    padding-left: 0;
}

/* li */
.projectElement {
    height: 200px;
    width: 1000px;
    margin-top: 4.5vh;
    list-style: none;
}

/* img */
.PLEIcon {
    border-radius: 3px;
    vertical-align: middle;
    /* box-shadow: 2px 2px 2px 2px  #dcdcdc; */
    border: 2px solid #9c9797;
    height: 190px;
    width: 253px;
    float: left;
}

/* a */
.PLEText {
    float: left;
    margin-left: 50px;
    margin-top: 50px;
    text-decoration: none;
    color: var(--text-color);
}

/* p */
.title {
    margin-top: 1rem;
    margin-bottom: 4px;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    font-size: 23px;
    width: 650px;
    text-align: left;
}

.date {
    padding-top: 0;
    margin-top: 0;
    font-size: 18px;
    /* font-family:'Roboto', sans-serif; */
    float: left;
}

.title:hover {
    color: #a5a2a2
}

.box {
    float: left;
    width: 600px;
    padding-top: 50px;
    padding-left: 50px;
}
</style>